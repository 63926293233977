import React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import Footer from "../components/footer"

import IconRocket from "../images/icons/rocket.svg"
import IconAstronaut from "../images/icons/user-astronaut.svg"
import IconLinkedIn from "../images/icons/linkedin.svg"
import IconGithub from "../images/icons/github-alt.svg"
import IconEnvelope from "../images/icons/envelope.svg"

const IndexPage = () => (
    <>
        <Seo title="Scott Luxford - UI/UX Designer &amp; Developer" />
        <div id="main">
            <h1 className="id">
                <span className="id-name">Scott<br /> Luxford</span>
                <span className="id-title">Designer +<br /> Developer</span>
                <span className="id-areas">UI / UX / Frontend / Growth</span>
            </h1>
            <nav>
                <Link to="/projects"><span className="sr-only">View my</span><img role="presentation" src={IconRocket} /> Projects</Link>
                <Link to="/about"><img role="presentation" src={IconAstronaut} /> About me</Link>
                <a href="https://www.linkedin.com/in/scottluxford/" rel="external"><span className="sr-only">Connect with me on</span><img role="presentation" src={IconLinkedIn} /> LinkedIn</a>
                <a href="https://github.com/scottluxford" rel="external"><span className="sr-only">Visit my</span><img role="presentation" src={IconGithub} /> Github</a>
                <a href="mailto:design@scottluxford.com"><span className="sr-only">Send me an</span><img role="presentation" src={IconEnvelope} /> Email</a>
            </nav>
        </div>

        <Footer />
    </>
)

  export default IndexPage
